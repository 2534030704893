.swiper .swiper-pagination-bullet {
  margin: 0 0 39px !important;
  width: 6px;
  height: 6px;
}
.swiper .swiper-pagination {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: center;
  gap: 15px;
}

@media only screen and (max-width: 1140px) {
  .swiper .swiper-pagination-bullet {
    margin: 0 0 12px !important;
  }
}
